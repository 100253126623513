import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import toast from 'react-hot-toast';
import { v4 as uuidv4 } from 'uuid';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { setDiscountDetails } from '../../slices/couponSlice';

const CheckoutForm = ({ setSelectedPaymentMode }) => {
    const [sessionId, setSessionId] = useState('');
    const userToken = JSON.parse(localStorage.getItem('token'));
    const userString = localStorage.getItem("user");
    const user = JSON.parse(userString);
    const [mobile, setmobile] = useState("");
    const [phone, setphone] = useState("");
    const [isButtonDisabled, setButtonDisabled] = useState(false);
    const [isCODSelected, setCODSelected] = useState(false);
    const [showCODModal, setShowCODModal] = useState(false);
    const [showDiscountMessage, setShowDiscountMessage] = useState(true);
    const { couponId } = useSelector((state) => state.coupon);
    const [discountAmount, setDiscountAmount] = useState(0);
    const [couponDiscount, setCouponDiscount] = useState('');
    const { discount, discountType, totalAmount } = useSelector(state => state.coupon);
    const [coupon, setCoupon] = useState('');
    const [couponMessage, setCouponMessage] = useState('');
    const [couponApplied, setCouponApplied] = useState(false);
    const dispatch = useDispatch();
    let email = '';

    if (userToken) {
        email = user.email;
    }

    const { cart } = useSelector((state) => state);
    const {
        register,
        handleSubmit,
        formState: { errors },
        watch,
        setValue
    } = useForm({
        defaultValues: {
            billing_same_as_shipping: true
        }
    });

    const navigate = useNavigate();

    const watchBillingSameAsShipping = watch('billing_same_as_shipping', true);

    const getSessionId = () => {
        let sessionId = localStorage.getItem('sessionId');
        if (!sessionId) {
            sessionId = uuidv4();
            localStorage.setItem('sessionId', sessionId);
        }
        return sessionId;
    };
    // const handlePaymentModeChange = (event) => {
    //     const mode = event.target.value;
    //     setShowDiscountMessage(mode === 'cod');
    //     setSelectedPaymentMode(mode);
    // };

    const handlePaymentModeChange = (event) => {
        const mode = event.target.value;
        setSelectedPaymentMode(mode);
    };

    useEffect(() => {
        setSessionId(getSessionId());
    }, []);

    useEffect(() => {
        if (watchBillingSameAsShipping) {
            setValue('billing_name', watch('shipping_name'));
            setValue('billing_email', watch('shipping_email'));
            setValue('billing_phone', watch('shipping_phone'));
            setValue('billing_address_line1', watch('shipping_address_line1'));
            setValue('billing_address_line2', watch('shipping_address_line2'));
            setValue('billing_city', watch('shipping_city'));
            setValue('billing_state', watch('shipping_state'));
            setValue('billing_zip_code', watch('shipping_zip_code'));
            setValue('billing_country', watch('shipping_country'));
        }
    }, [watchBillingSameAsShipping, watch, setValue]);


    const errorFunction = () => {
        toast.error("Cart is empty. Add items to your cart before placing an order.");

        setTimeout(() => {
            navigate("/cart");
        }, 2000);

    };

    const handlebtnDisable = () => {
        setButtonDisabled(true);
    };

    const handleCheckAndApplyCoupon = (e) => {
        e.preventDefault();
        if (coupon.trim() !== '') {
            const apiUrl = `https://admin.theroarfit.com/api/check-coupon/${coupon}`;
            axios.get(apiUrl)
                .then(response => {
                    const results = response.data;
                    if (results.id) {
                        if (results.min_amount !== null && results.min_amount !== undefined && totalAmount > results.min_amount) {
                            dispatch(setDiscountDetails({
                                couponId: results.id,
                                discount: results.discount,
                                discountType: results.discount_type
                            }));
                            setCouponMessage('Coupon applied successfully!');
                            setCouponApplied(true);
                        } else {
                            setCouponMessage('Your cart value is not greater than the minimum value required for this coupon.');
                            setCouponApplied(false);
                            dispatch(setDiscountDetails({
                                couponId: null,
                                discount: 0,
                                discountType: null
                            }));
                        }
                    } else {
                        setCouponMessage('Coupon code is not valid. Please check the code and try again.');
                        setCouponApplied(false);
                        dispatch(setDiscountDetails({
                            couponId: null,
                            discount: 0,
                            discountType: null
                        }));
                    }
                })
                .catch(error => {
                    console.error('Error fetching coupon details:', error);
                    setCouponMessage(error.response.data.message);
                    setCouponApplied(false);
                    dispatch(setDiscountDetails({
                        couponId: null,
                        discount: 0,
                        discountType: null
                    }));
                });
        } else {
            console.log('Please enter a coupon code.');
            setCouponMessage('Please enter a coupon code.');
            setCouponApplied(false);
            dispatch(setDiscountDetails({
                couponId: null,
                discount: 0,
                discountType: null
            }));
        }
    };

    const handleCouponChange = (e) => {
        const value = e.target.value;
        setCoupon(value);
        if (value.trim() === '') {
            setCouponMessage('');
            setCouponApplied(false);
            setCouponDiscount('');
            dispatch(setDiscountDetails({
                couponId: null,
                discount: 0,
                discountType: null
            }));
        }
    };


    return (
        <div className="p-4 bg-white shadow-lg rounded-md font-Quicksand">
            {showCODModal && (
                <div className="fixed z-10 inset-0 overflow-y-auto bg-black bg-opacity-50 flex items-center justify-center px-4">
                    <div className="bg-white rounded-lg p-8">
                        <div className="text-center">
                            <h2 className="text-xl font-bold mb-4">COD Not Available</h2>
                            <p className="text-gray-600 mb-4">
                                Sorry, due to an excessive number of fake orders, we are no longer accepting COD orders.</p>
                            <button onClick={() => setShowCODModal(false)} className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">OK</button>
                        </div>
                    </div>
                </div>
            )}
            <form action={'https://pay.theroarfit.com/order-create'} method='post' onSubmit={handlebtnDisable}>
                {
                    !userToken && (
                        <div className="mb-4">
                            <label className="block mb-2 font-semibold text-gray-900">
                                Contact Email:
                                <input
                                    type="email"
                                    {...register('contact_email', { required: 'Email is required' })}
                                    className="outline-none bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg  block w-full p-2.5 form-input "
                                    placeholder="Enter your email"
                                    required={true}
                                />
                                <p className="text-red-500">{errors.contact_email?.message}</p>
                            </label>
                            <p className="text-sm text-green-600 mt-2 font-semibold">
                                Kindly utilize this email for any future account registrations.
                            </p>
                        </div>
                    )
                }
                {/* Shipping Address */}
                <div className="mb-6">
                    <h3 className="text-xl font-semibold mb-4 text-gray-900">Shipping Address</h3>
                    <div className="grid grid-cols-1 gap-4">
                        <div className=''>
                            <label className="block mb-2 font-semibold text-gray-900">
                                Name:
                                <input
                                    type="text"
                                    {...register('shipping_name', { required: 'Name is required' })}
                                    className="outline-none bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg  block w-full p-2.5 form-input "
                                    placeholder="Enter your name"
                                    required={true}
                                />
                                <p className="text-red-500">{errors.shipping_name?.message}</p>
                            </label>
                            <label className="block mb-2 font-semibold text-gray-900">
                                Email:
                                <input
                                    type="email"
                                    {...register('shipping_email', { required: 'Email is required' })}
                                    className="outline-none bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg  block w-full p-2.5 form-input "
                                    placeholder="Enter your email"
                                    required={true}
                                />
                                <p className="text-red-500">{errors.shipping_email?.message}</p>
                            </label>
                            <label className="block mb-2 font-semibold text-gray-900">
                                Phone:
                                <input
                                    type="tel"
                                    value={mobile}
                                    {...register('shipping_phone', { required: 'Phone is required' })}
                                    className="bg-gray-50 border outline-none border-gray-300 text-gray-900 text-sm rounded-lg  block w-full p-2.5 form-input "
                                    placeholder="Enter your phone number"
                                    required={true}
                                    onChange={
                                        (e) => {
                                            // Allow only digits (0-9)
                                            const inputValue = e.target.value.replace(/\D/g, '');

                                            // Limit the input to 10 digits
                                            const limitedInput = inputValue.slice(0, 10);

                                            // Update the state
                                            setmobile(limitedInput);
                                        }

                                    }

                                />
                                <p className="text-red-500">{errors.shipping_phone?.message}</p>
                            </label>
                            <label className="block mb-2 font-semibold text-gray-900">
                                Address Line 1:
                                <textarea
                                    {...register('shipping_address_line1', { required: 'Address is required' })}
                                    className="bg-gray-50 border outline-none border-gray-300 text-gray-900 text-sm rounded-lg  block w-full p-2.5 form-input "
                                    placeholder="Enter your address"
                                    required={true}
                                />
                                <p className="text-red-500">{errors.shipping_address_line1?.message}</p>
                            </label>
                            <label className="block mb-2 font-semibold text-gray-900">
                                Address Line 2:
                                <textarea
                                    {...register('shipping_address_line2')}
                                    className="bg-gray-50 border outline-none border-gray-300 text-gray-900 text-sm rounded-lg  block w-full p-2.5 form-input "
                                    placeholder="Enter additional address (optional)"
                                />
                            </label>
                        </div>
                    </div>
                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                        <div>
                            <div>
                                <label className="block mb-2 font-semibold text-gray-900">
                                    City:
                                    <input
                                        type="text"
                                        {...register('shipping_city', { required: 'City is required' })}
                                        className="bg-gray-50 border outline-none border-gray-300 text-gray-900 text-sm rounded-lg  block w-full p-2.5 form-input "
                                        placeholder="Enter your city"
                                        required={true}
                                    />
                                    <p className="text-red-500">{errors.shipping_city?.message}</p>
                                </label>
                            </div>
                            <div>
                                <label className="block mb-2 font-semibold text-gray-900">
                                    State:
                                    <input
                                        type="text"
                                        {...register('shipping_state', { required: 'State is required' })}
                                        className="bg-gray-50 border outline-none border-gray-300 text-gray-900 text-sm rounded-lg  block w-full p-2.5 form-input t"
                                        placeholder="Enter your state"
                                        required={true}
                                    />
                                    <p className="text-red-500">{errors.shipping_state?.message}</p>
                                </label>
                            </div>
                        </div>
                        <div>
                            <div>
                                <label className="block mb-2 font-semibold text-gray-900">
                                    ZIP Code:
                                    <input
                                        type="text"
                                        {...register('shipping_zip_code', { required: 'ZIP Code is required' })}
                                        className="bg-gray-50 border outline-none border-gray-300 text-gray-900 text-sm rounded-lg  block w-full p-2.5 form-input "
                                        placeholder="Enter your ZIP Code"
                                        required={true}
                                    />
                                    <p className="text-red-500">{errors.shipping_zip_code?.message}</p>
                                </label>
                            </div>
                            <div>
                                <label className="block mb-2 font-semibold text-gray-900">
                                    Country:
                                    <select
                                        {...register('shipping_country', { required: 'Country is required' })}
                                        className="form-select bg-gray-50 border outline-none border-gray-300 text-gray-900 text-sm rounded-lg  block w-full p-2.5"
                                        required={true}
                                    >
                                        <option value="">Select your country</option>
                                        <option value="India">India</option>
                                    </select>
                                    <p className="text-red-500">{errors.shipping_country?.message}</p>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Payment Mode */}
                <div className="mb-4">
                    <label className="block mb-2 font-bold text-xl text-gray-900">Payment Mode:</label>
                    {/* {showDiscountMessage && (
                        <p className='text-red-500 mb-1 font-semibold'>Select Pay Online to get 5% discount</p>
                    )} */}
                    <p className='text-red-500 mb-1 font-semibold'>After check out you will get 5% additional discount. Happy to buy our products</p>
                    <div className="flex  flex-col gap-2 sm:flex-row">
                        <label className="inline-flex items-center">
                            <input
                                type="radio"
                                {...register('payment_mode', { required: 'Payment mode is required' })}
                                value="phonepe"
                                onChange={handlePaymentModeChange}
                                className="form-radio"
                                defaultChecked={true}
                            />
                            <span className="ml-2 font-semibold text-gray-900">Pay Online</span>
                        </label>
                        {/* <label className="inline-flex items-center mr-4">
                            <input
                                type="radio"
                                {...register('payment_mode', { required: 'Payment mode is required' })}
                                value="cod"
                                onChange={handlePaymentModeChange}
                                className="form-radio"
                                required={true}
                                disabled={true}
                            />
                            <span className="ml-2 font-semibold text-gray-900">Cash on Delivery (COD)</span>
                        </label> */}
                    </div>
                    <p className="text-red-500">{errors.payment_mode?.message}</p>
                    <p className='text-black'>Note : Due to maximum cod orders are returned by customers that’s why we terminated the cod option
                    </p>
                </div>

                {/* coupon code section */}
                <div className="container text-gray-900 mb-2">
                    <p className="text-gray-900 font-semibold">Have a coupon code ? </p>
                    <div className="flex items-center w-full gap-2 mt-2">
                        <input
                            type="text"
                            className="border border-gray-300 rounded-md px-4 py-2 focus:outline-none w-full font-semibold"
                            value={coupon}
                            onChange={handleCouponChange}
                            placeholder="Discount coupon or gift card"
                        />
                        <button
                            className="bg-black text-white px-4 py-2 rounded-md focus:outline-none"
                            onClick={handleCheckAndApplyCoupon}
                        >
                            {couponApplied ? 'Applied' : 'Apply'}
                        </button>
                    </div>

                    {couponMessage && (
                        <div className={`text-sm font-semibold ${couponApplied ? 'text-green-500' : 'text-red-500'}`}>
                            {couponMessage}
                        </div>
                    )}
                </div>

                {/* Billing Address */}
                <div className="mb-6">
                    <h3 className="text-xl font-semibold mb-4 text-gray-900">Billing Address</h3>
                    <label className="block mb-4 font-semibold text-gray-900">
                        <input
                            type="checkbox"
                            {...register('billing_same_as_shipping')}
                            className="mr-2 "
                        />
                        Same as Shipping Address
                    </label>
                    {!watchBillingSameAsShipping && (
                        <div className="grid grid-cols-1 gap-4">
                            <div>
                                <label className="block mb-2 font-semibold text-gray-900">
                                    Name:
                                    <input
                                        type="text"
                                        {...register('billing_name', { required: 'Name is required' })}
                                        className="bg-gray-50 border outline-none border-gray-300 text-gray-900 text-sm rounded-lg  block w-full p-2.5 form-input"
                                        placeholder="Enter your name"
                                        required={true}
                                    />
                                    <p className="text-red-500">{errors.billing_name?.message}</p>
                                </label>
                                <label className="block mb-2 font-semibold text-gray-900">
                                    Email:
                                    <input
                                        type="email"
                                        {...register('billing_email', { required: 'Email is required' })}
                                        className="bg-gray-50 border outline-none border-gray-300 text-gray-900 text-sm rounded-lg  block w-full p-2.5 form-input"
                                        placeholder="Enter your email"
                                        required={true}
                                    />
                                    <p className="text-red-500">{errors.billing_email?.message}</p>
                                </label>
                                <label className="block mb-2 font-semibold text-gray-900">
                                    Phone:
                                    <input
                                        type="tel"
                                        value={phone}
                                        {...register('billing_phone', { required: 'Phone is required' })}
                                        className="bg-gray-50 border outline-none border-gray-300 text-gray-900 text-sm rounded-lg  block w-full p-2.5 form-input"
                                        placeholder="Enter your phone number"
                                        required={true}
                                        onChange={
                                            (e) => {
                                                const inputValue = e.target.value.replace(/\D/g, '');
                                                const limitedInput = inputValue.slice(0, 10);
                                                setphone(limitedInput);
                                            }

                                        }
                                    />
                                    <p className="text-red-500">{errors.billing_phone?.message}</p>
                                </label>
                                <label className="block mb-2 font-semibold text-gray-900">
                                    Address Line 1:
                                    <textarea
                                        {...register('billing_address_line1', { required: 'Address is required' })}
                                        className="bg-gray-50 border outline-none border-gray-300 text-gray-900 text-sm rounded-lg  block w-full p-2.5 form-input"
                                        placeholder="Enter your address"
                                        required={true}
                                    />
                                    <p className="text-red-500">{errors.billing_address_line1?.message}</p>
                                </label>
                                <label className="block mb-2 font-semibold text-gray-900">
                                    Address Line 2:
                                    <textarea
                                        {...register('billing_address_line2')}
                                        className="bg-gray-50 border outline-none border-gray-300 text-gray-900 text-sm rounded-lg  block w-full p-2.5 form-input"
                                        placeholder="Enter additional address (optional)"
                                    />
                                </label>
                                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                    <div>
                                        <div>
                                            <label className="block mb-2 font-semibold text-gray-900">
                                                City:
                                                <input
                                                    type="text"
                                                    {...register('billing_city', { required: 'City is required' })}
                                                    className="bg-gray-50 border outline-none border-gray-300 text-gray-900 text-sm rounded-lg  block w-full p-2.5 form-input"
                                                    placeholder="Enter your city"
                                                    required={true}
                                                />
                                                <p className="text-red-500">{errors.billing_city?.message}</p>
                                            </label>
                                        </div>
                                        <div>
                                            <label className="block mb-2 font-semibold text-gray-900">
                                                State:
                                                <input
                                                    type="text"
                                                    {...register('billing_state', { required: 'State is required' })}
                                                    className="bg-gray-50 border outline-none border-gray-300 text-gray-900 text-sm rounded-lg  block w-full p-2.5 form-input"
                                                    placeholder="Enter your state"
                                                    required={true}
                                                />
                                                <p className="text-red-500">{errors.billing_state?.message}</p>
                                            </label>
                                        </div>
                                    </div>
                                    <div>
                                        <div>
                                            <label className="block mb-2 font-semibold text-gray-900">
                                                ZIP Code:
                                                <input
                                                    type="text"
                                                    {...register('billing_zip_code', { required: 'ZIP Code is required' })}
                                                    className="bg-gray-50 border outline-none border-gray-300 text-gray-900 text-sm rounded-lg  block w-full p-2.5 form-input"
                                                    placeholder="Enter your ZIP Code"
                                                    required={true}
                                                />
                                                <p className="text-red-500">{errors.billing_zip_code?.message}</p>
                                            </label>
                                        </div>
                                        <div>
                                            <label className="block mb-2 font-semibold text-gray-900">
                                                Country:
                                                <select
                                                    {...register('billing_country', { required: 'Country is required' })}
                                                    className="form-select bg-gray-50 border outline-none border-gray-300 text-gray-900 text-sm rounded-lg  block w-full p-2.5"
                                                    placeholder="Select your country"
                                                    required={true}
                                                >
                                                    <option value="">Select your country</option>
                                                    <option value="India">India</option>
                                                </select>
                                                <p className="text-red-500">{errors.billing_country?.message}</p>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
                <label className="block mb-4 font-semibold text-gray-900">
                    Order Note (Optional):
                    <textarea
                        {...register('order_note')}
                        className="w-full form-textarea bg-gray-50 border outline-none border-gray-300 text-gray-900 text-sm rounded-lg  block p-2.5"
                        placeholder="Add a note to your order..."
                    />
                </label>
                <input type="hidden" name="session_id" value={sessionId} />
                <input type="hidden" name="coupon_id" value={couponId} />
                {userToken && (
                    <input type="hidden" name="contact_email" value={email} />
                )}
                <button
                    type="submit"
                    className="bg-black text-white px-6 py-3 rounded-md hover:bg-gray-200 hover:text-black border w-full font-semibold"
                    disabled={isButtonDisabled || (isCODSelected && true)}
                >
                    Place Order
                </button>
            </form>
            <div className='flex gap-6 mt-4'>
                <p className='text-black text-lg underline decoration-solid font-semibold'><Link to={"/return-policy"}>Return policy</Link></p>
                <p className='text-black text-lg underline decoration-solid font-semibold'><Link to={"/refund-policy"}>Refund policy</Link></p>
                <p className='text-black text-lg underline decoration-solid font-semibold'><Link to={"/shipping-policy"}>Shipping policy</Link></p>
            </div>
        </div>

    );
};

export default CheckoutForm;
